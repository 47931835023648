import React from "react";
import {Layout, Row, Col, Typography} from 'antd';
import Navbar from './components/Navbar'
import Home from "./home";
import {FacebookFilled} from "@ant-design/icons";
const {Text}= Typography
const {Content, Footer} = Layout;
const style = {
    xs: {span: 24},
    sm: {span: 12},
    md: {span: 12},
    lg: {span: 12},
    xl: {span: 12},
    xxl: {span: 12},
}
const Index = () => {
    return <Layout className="layout">
        <Navbar/>
        <Content>
            <Home/>
        </Content>
        <Footer style={{padding: "24px 12px"}}>
            <Row>
                <Col {...style} style={{textAlign:"center"}}>
                    <a href="https://www.facebook.com/Nicole-112334208191677" alt="Nicole團購facebook粉絲團" target="_blank">
                        <FacebookFilled style={{fontSize: "26px"}}/>
                    </a>
                </Col>
                <Col {...style} style={{textAlign:"center"}}>
                    <Text>
                        Copyright © 2022 Nicole.All rights reserved
                    </Text>
                </Col>
            </Row>
        </Footer>
    </Layout>
}

export default Index;
