import {Empty} from "antd";
import React from "react";
import ShoppingCart from "./ShoppingCart";

const ShoppingCartList = ({dataList}) => {
    const dataContent = dataList.map((item, index) => {
        return (
            <ShoppingCart
                key={index}
                item={item}
                index={index}
            />
        );
    })
    const emptyContent = <Empty description={<span>暫無資料</span>}></Empty>
    return (
        <>
            {
                (dataList.length > 0) ? dataContent : emptyContent
            }
        </>
    )
}

export default ShoppingCartList
