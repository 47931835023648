const ListenImage = () => {
    let status = false
    setInterval(() => {
        const watcher = new IntersectionObserver(onEnterView)
        const lazyImages = document.querySelectorAll('[data-src]')
        if (lazyImages.length === 0) return
        if (status === true)  return

        status = true
        for (let image of lazyImages) {
            if (image.getAttribute("data-image-watcher") === "false") {
                image.setAttribute("data-image-watcher", "true")
                watcher.observe(image)
            }
        }
        status = false
        function onEnterView(entries, observer) {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    console.log(entry.isIntersecting)
                    const img = entry.target
                    img.src = img.getAttribute("data-src")
                    observer.unobserve(img)
                }
            }
        }

    },500)
}
export default ListenImage
