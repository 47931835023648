import React, {useEffect} from "react"
import {Layout} from "antd"
import {useLiff} from "react-liff";
import store from "../../store"
import Loading from "../components/Loading";
import {useSelector} from "react-redux";
import {addUsersInfo, getAuthToken} from "../../model/users";
import {addChannelsInfo} from "../../model/channels";

const findGetParameter = (parameterName) => {
    let result = null,
        tmp = [];
    let items = location.search.substr(1).split("&");
    for (let index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

const switchCase = async () => {
    localStorage.removeItem("id")
    let action = findGetParameter("action")
    let menusId = findGetParameter("menusId")
    let groupsId = findGetParameter("groupsId")
    switch (action) {
        case store.getState().processingReducer.routerAction.shoppingCarts:
            if (!menusId || !groupsId) {
                throw {message: "parameters error"}
            }
            localStorage.setItem("menusId", menusId)
            localStorage.setItem("groupsId", groupsId)
            location.replace("/shopping-carts")
            break
        case store.getState().processingReducer.routerAction.items:
            if (!menusId || !groupsId) {
                throw {message: "parameters error"}
            }
            localStorage.setItem("menusId", menusId)
            localStorage.setItem("groupsId", groupsId)
            location.replace("/items")
            break
        case store.getState().processingReducer.routerAction.orders:
            if (menusId || groupsId) {
                localStorage.setItem("menusId", menusId)
                localStorage.setItem("groupsId", groupsId)
            }
            location.replace("/orders")
            break
        case store.getState().processingReducer.routerAction.ordersInfo:

            let id = findGetParameter("id")
            location.replace("/orders/info?id=" + id)
            break
        default:
            throw {message: "action error"}
    }
}
const isProduction = process.env.NODE_ENV === 'production';
const Processing = () => {
    const { error, isLoggedIn, isReady, liff } = useLiff();
    const testLiffProcess = async () => {
        let profile = {
            userId: "U8d5adb4c45c3c3c66ccb6500e14eb296",
            displayName: "dada欣達",
            pictureUrl: "https://sprofile.line-scdn.net/0h3Gvsv2WlbGhbCkV_CfASFytabwJ4ezV6dmwkW2ZdNFBkbSprJT90Dm4JNAoxOSM7IGV0DzldM1FXGRsORVyQXFw6Ml9iPCs3dG0ljw",
        }
        store.dispatch({type: store.getState().usersReducer.action.setUserInfo, payload: profile})
        localStorage.setItem("userId", profile.userId)
    }
    const prodLiffProcess = async () => {
        const profile = await liff.getProfile();
        if (["", undefined].indexOf(profile.userId) >= 0) {
            throw {message: "Line使用者資訊錯誤"}
        }
        store.dispatch({type: store.getState().usersReducer.action.setUserInfo, payload: profile})
        localStorage.setItem("userId", profile.userId)
    }
    useEffect(() => {
        (async () => {
            try {
                store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: true})
                if (isProduction) {
                    if (!isLoggedIn) return
                    await prodLiffProcess()
                } else {
                    await testLiffProcess()
                }
                await addUsersInfo()
                await getAuthToken()
                await addChannelsInfo()
                await switchCase()
                setTimeout(async () => {
                    store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false})
                }, 1000)
            } catch (e) {
                store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false})
                store.dispatch({type: store.getState().modalReducer.action.setErrorModalMessage, payload: e.message})
                store.dispatch({type: store.getState().modalReducer.action.setErrorModal, payload: true})
            }
        })();
    }, [liff, isLoggedIn]);
    return <Layout style={{height: "100vh"}}>
        <Loading/>
    </Layout>
}

export default Processing
