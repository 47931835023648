import React from "react";
import Item from "./Item";
import {Empty} from "antd";

const ItemList = ({dataList}) => {
    const dataContent = dataList.map((item, index) => {
        return (
            <Item
                key={index}
                item={item}
                index={index}
            />
        );
    })
    const emptyContent = <Empty description={<span>團購單無資料</span>}></Empty>
    return (
        <>
        {
            (dataList.length > 0) ? dataContent : emptyContent
        }
        </>
    )
}
export default ItemList
