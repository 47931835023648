import {Modal, Image, InputNumber, Radio, message, Typography, Skeleton} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import store from "../../../store";
import {ExclamationCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {addShoppingCartsInfo} from "../../../model/shoppingCarts";
const {Text} = Typography
const AddShoppingCartModal = () => {
    const {confirm} = Modal;
    const dataInfo = useSelector((state) => state.itemsReducer.dataInfo)
    const isOpen = useSelector((state) => state.modalReducer.isItemModalOpen)
    let [amount, setAmount] = useState(1)
    const [specsId, setSpecsId] = useState(0)
    const handleOk = async () => {
        try {
            if (specsId === 0){
                return message.error("請至少選擇一個規格")
            } else if (amount <= 0) {
                return message.error("數量必須大於0")
            }
            store.dispatch({type: store.getState().modalReducer.action.setItemModal, payload: false})
            store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: true});
            store.dispatch({type: store.getState().itemsReducer.action.setAmount, payload: amount})
            store.dispatch({type: store.getState().itemsReducer.action.setSelectedSpecsId, payload: specsId})
            await addShoppingCartsInfo()
            store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
            confirm({
                title: "加入成功是否前往購物車？",
                icon: <ExclamationCircleOutlined/>,
                okText:"確認",
                cancelText:"取消",
                onOk() {
                    let params = new URLSearchParams({
                        action: store.getState().processingReducer.routerAction.shoppingCarts,
                        menusId: localStorage.getItem("menusId"),
                        groupsId: localStorage.getItem("groupsId"),
                    })
                    location.replace("/processing?"+params)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } catch (e) {
            store.dispatch({type: store.getState().modalReducer.action.setItemModal, payload: false})
            store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
        }
    }
    const handleCancel = () => {
        store.dispatch({type: store.getState().modalReducer.action.setItemModal, payload: false})
    }
    const afterClose = () => {
        setAmount(1)
        setSpecsId(0)
    }
    useEffect(() => {
    }, [isOpen])
    return <Modal
        title="加入購物車"
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={afterClose}
        open={isOpen}
        okText="加入購物車"
        cancelText="關閉">
        <table>
            <thead>
            <tr>
                <td style={{maxWidth: "80px", padding: "6px"}}>
                    <Image
                        style={{maxHeight: "360px", maxWidth: "360px"}}
                        preview={false}
                        src={dataInfo.filePath}
                        placeholder={
                            <Skeleton.Image style={{minHeight: "360px", minWidth: "360px"}} active={true} />
                        }
                    />
                </td>
                <td style={{verticalAlign: "top", lineHeight: "36px"}}>
                    <Text>{dataInfo.name}</Text>
                    <br></br>
                    <Text type="danger">NT${dataInfo.price}</Text>
                </td>
            </tr>
            <tr>
                <td style={{verticalAlign: "top", lineHeight: "36px"}} colSpan={2}>
                    規格：<br></br>
                    <Radio.Group value={specsId} onChange={(e) => setSpecsId(e.target.value)}>
                        {dataInfo.specList ? (
                            dataInfo.specList.map((item, index) => {
                                return <Radio value={item.id} key={index}>{item.name}</Radio>
                            })
                        ) : (
                            ""
                        )}
                    </Radio.Group>
                </td>
            </tr>
            <tr>
                <td style={{verticalAlign: "top", lineHeight: "30px"}} colSpan={2}>
                    數量：<br></br>
                    <InputNumber
                        style={{marginTop: "8px"}}
                        value={amount}
                        onChange={(val) => {
                            setAmount(val)
                        }}
                        addonBefore={<MinusOutlined onClick={() => {
                            amount--
                            if (amount < 0) {
                                amount = 0
                            }
                            setAmount(amount)
                        }}/>}
                        addonAfter={<PlusOutlined onClick={() => setAmount(amount + 1)}/>}
                    />
                </td>
            </tr>
            </thead>
        </table>
    </Modal>
}
export default AddShoppingCartModal
