const initState = {
    action: {
        setMenusOptions: "options/setMenusOptions",
        setStatusOptions: "options/setStatusOptions",
        setMenusOptionSelected: "options/setMenusOptionSelected",
        setStatusOptionSelected: "options/setStatusOptionSelected",
    },
    menusOptions: [],
    statusOptions: [],
    menusOptionSelected: "",
    statusOptionSelected: 0,
};
const optionsReducer = (state = initState, action) => {
    switch (action.type) {
        case state.action.setMenusOptions:
            return {
                ...state,
                menusOptions: action.payload,
            }
        case state.action.setStatusOptions:
            return {
                ...state,
                statusOptions: action.payload,
            }
        case state.action.setMenusOptionSelected:
            return {
                ...state,
                menusOptionSelected: action.payload,
            }
        case state.action.setStatusOptionSelected:
            return {
                ...state,
                statusOptionSelected: action.payload,
            }
        default:
            return state;
    }
};

export default optionsReducer;
