import ChannelsApi from "../apis/channels";

export const addChannelsInfo = async () => {
    try {
        let data = {
            "groupsId": Number(localStorage.getItem("groupsId")),
        }
        await ChannelsApi.addChannelsInfo(data)
    } catch {}
};
