import {Row, Col, Typography, Card, Button, BackTop} from "antd";
import React from "react";
import Teach from "../teach";
import Qrcode from "../qrcode";
import TopCarousel from "./components/TopCarousel";
import {UpCircleOutlined} from "@ant-design/icons";

const {Title, Text} = Typography;

const Home = () => {
    return <Row className="home-site-layout-content">
        <Col span={24} style={{paddingBottom: "24px"}}>
            <TopCarousel />
        </Col>
        <Col span={24} style={{padding: "24px 0", textAlign:"center"}}>
            <Title id="intro">操作說明</Title>
            <Teach />
        </Col>
        <Col span={24} style={{padding: "24px 0", textAlign:"center"}}>
            <Title id="follow-us">加入我們</Title>
            <Text>加入好友，以獲得最新資訊或申請試用！</Text>
            <Qrcode />
        </Col>
        <BackTop style={{marginBottom: "16px"}}>
            <UpCircleOutlined style={{borderRadius:"24px", fontSize:"48px", background: "#274b10", color:"#ffffff"}}/>
        </BackTop>
    </Row>
}
export default Home
