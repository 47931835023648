import {Modal, Typography} from "antd";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import { ExclamationCircleFilled } from '@ant-design/icons';
const { error } = Modal;
const { Title } = Typography;

const ErrorModal = () => {
    const isOpen = useSelector((state) => state.modalReducer.isErrorModalOpen)
    const message = useSelector((state) => state.modalReducer.errorMessage)
    useEffect(()=>{
        if (isOpen){
            error({
                icon: <ExclamationCircleFilled />,
                title: "Error",
                content: <Title level={5}>{message}</Title>,
                open: isOpen,
                closable: false,
                footer: null,
                okButtonProps: {
                    hidden: true,
                },
            })
        }
    }, [isOpen])
}
export default ErrorModal
