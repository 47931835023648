const initState = {
    action: {
        setOffset: "shoppingCarts/setOffset",
        setPageSize: "shoppingCarts/setPageSize",
        setDataList: "shoppingCarts/setDataList",
        setAddAmount: "shoppingCarts/setAddAmount",
        setMinusAmount: "shoppingCarts/setMinusAmount",
        setDeleteDataList: "shoppingCarts/setDeleteDataList",
    },
    offset: 1,
    pageSize: 12,
    dataList: [],
    totalCount: 0,
};
const shoppingCartsReducer = (state = initState, action) => {
    switch (action.type) {
        case state.action.setOffset:
            return {
                ...state,
                offset: action.payload
            }
        case state.action.setPageSize:
            return {
                ...state,
                pageSize: action.payload
            }
        case state.action.setDataList:
            return {
                ...state,
                dataList: action.payload.dataList,
                totalCount: action.payload.totalCount,
            }
        case state.action.setAddAmount:
            return {
                ...state,
                dataList: state.dataList.map((item, i) => {
                    if (i === action.payload) {
                        let amount = item.amount + 1
                        if (amount <= 0){
                            amount = 0
                        }
                        return {...item, amount: amount};
                    } else {
                        return item;
                    }
                })
            }
        case state.action.setMinusAmount:
            return {
                ...state,
                dataList: state.dataList.map((item, i) => {
                    if (i === action.payload) {
                        let amount = item.amount - 1
                        if (amount <= 0){
                            amount = 0
                        }
                        return {...item, amount: amount};
                    } else {
                        return item;
                    }
                })
            }
        case state.action.setDeleteDataList:
            return {
                ...state,
                dataList: state.dataList.filter((item, i) => {
                    if (i !== action.payload) {
                        return item;
                    }
                })
            }
        default:
            return state;
    }
};

export default shoppingCartsReducer;
