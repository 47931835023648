import {Col, Row, Spin} from "antd";
import React from "react";
import logo from "../../assets/logo-g.png";
import {useSelector} from "react-redux";

const Loading = () => {
    const isLoading = useSelector((state) => state.loadingReducer.isLoading)
    const indicator = <div>
        <div style={{display: "flex", height: "100vh", alignItems: "center"}}>
            <Row>
                <Col span={24}>
                    <img src={logo} alt="" style={{width: "50px", borderRadius: "50%"}}/>
                </Col>
                <Col span={24}>
                    <span className="blink">Loading...</span>
                </Col>
            </Row>
        </div>
    </div>
    return  <Spin spinning={isLoading} indicator={indicator}/>
}
export default Loading
