import axios from "../utils/axios";

class UsersApi {
    static async addUsersInfo(data){
        return axios.fetch({
            method: "post",
            url: "/api/v1/users/add-users-info",
            data: data,
        });
    }
    static async getAuthToken(data){
        return axios.fetch({
            method: "post",
            url: "/api/v1/users/get-auth-token",
            data: data,
        });
    }
}

export default UsersApi
