import DrawBar from "../components/DrawBar";
import {Col, Row, Typography, Spin} from "antd";
import {useSelector} from "react-redux";
import ShoppingCartList from "./components/ShoppingCartList";
import SubmitShoppingCartButton from "./components/SubmitShoppingCartButton";
import {useEffect} from "react";
import {getShoppingCartsList} from "../../model/shoppingCarts";
import store from "../../store";

const {Title} = Typography

const ShoppingCarts = () => {
    const dataList = useSelector((state) => state.shoppingCartsReducer.dataList)
    const isLoading = useSelector((state) => state.loadingReducer.isLoading)
    const setupData = async () => {
        try {
            store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: true});
            await getShoppingCartsList()
            store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
        } catch (e) {
            store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
        }
    }
    useEffect(() => {
        setupData()
    }, [])
    return <>
        <DrawBar/>
        <div className={"container"}>
            <Spin spinning={isLoading}>
                <Row gutter={[12, 24]}>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Title level={3} style={{marginTop: "16px"}}>我的購物車</Title>
                    </Col>
                    <Col span={24}>
                        <ShoppingCartList dataList={dataList}/>
                    </Col>
                    <Col span={24}>
                        {dataList.length === 0 ? "" : <SubmitShoppingCartButton/>}
                    </Col>
                </Row>
            </Spin>
        </div>
    </>
}
export default ShoppingCarts
