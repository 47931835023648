import {Button, Carousel, Col, Row, Typography} from "antd";
import React from "react";
const {Title, Text} = Typography;
const style = {
    xs: {span: 24},
    sm: {span: 12},
    md: {span: 12},
    lg: {span: 12},
    xl: {span: 12},
    xxl: {span: 12},
}
const onChange = (currentSlide) => {};
const goAnchor = () => {
    scrollView("follow-us")
}
const carouselList = [
    {title: "讓團購更容易\n現在就開始", label: "", imgSrc: require("../../../assets/home/store.png")},
]

const scrollView = (id) => {
    document.querySelector('[id="' + id + '"]').scrollIntoView({
        behavior: 'smooth'
    })
}
const TopCarousel = () => (
    <Carousel afterChange={onChange} autoplay={true} className="home-step">
        {carouselList.map((item, index) => {
            const key = index + 1
            return <Row key={key}>
                <Col span={24} style={{
                    color: '#fff',
                    textAlign: 'center',
                    background: '#8fb882',
                }}>
                    <Row type="flex" justify="center" align="middle" style={{minHeight: "60vh"}}>
                        <Col {...style} style={{
                            padding: "24px"
                        }}>
                            <Text style={{
                                color: "#ffffff",
                                whiteSpace: 'pre-wrap',
                                fontWeight: "100",
                                letterSpacing: "8px",
                                fontSize: "18px"
                            }}>{item.label}</Text>
                            <Title level={2} style={{
                                color: "#ffffff",
                                whiteSpace: 'pre-wrap',
                                lineHeight: "48px",
                                fontWeight: "bold",
                                letterSpacing: "6px"
                            }}>
                                {item.title}
                            </Title>
                            <Button className="btn-out-line" style={{color: "#ffffff"}} onClick={goAnchor}>立即使用</Button>
                        </Col>
                        <Col {...style}>
                            <img src={item.imgSrc} alt="" style={{
                                margin: "auto",
                                width: "100%",
                                maxWidth: "300px"
                            }}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        })}
    </Carousel>
)
export default TopCarousel
