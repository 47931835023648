const initState = {
    action: {
        setOffset: "shoppingCarts/setOffset",
        setPageSize: "shoppingCarts/setPageSize",
        setDataList: "shoppingCarts/setDataList",
        setDataInfo: "shoppingCarts/setDataInfo",
    },
    offset: 1,
    pageSize: 12,
    totalCount: 0,
    dataList: [],
    dataInfo: {
        id: "",
        statusName: "",
        createdAt: "",
        updatedAt: "",
        menusName: "",
        usersName: "",
        allAmount: "",
        allPrice: "",
        itemsList: [],
    },
};
const ordersReducer = (state = initState, action) => {
    switch (action.type) {
        case state.action.setOffset:
            return {
                ...state,
                offset: action.payload
            }
        case state.action.setPageSize:
            return {
                ...state,
                pageSize: action.payload
            }
        case state.action.setDataList:
            return {
                ...state,
                dataList: action.payload.dataList,
                totalCount: action.payload.totalCount,
            }
        case state.action.setDataInfo:
            return {
                ...state,
                dataInfo: action.payload.dataInfo,
            }
        default:
            return state;
    }
};

export default ordersReducer;
