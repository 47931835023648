import store from "../store";
import OrdersApi from "../apis/orders";

export const getOrderStatusOptions = async () => {
    let res = await OrdersApi.getOrderStatusOptions()
    store.dispatch({
        type: store.getState().optionsReducer.action.setStatusOptions,
        payload: res.data.dataList
    })
};
export const addOrdersInfo = async () => {
    let data = {
        "groupsId": parseInt(localStorage.getItem("groupsId")),
        "menusId": parseInt(localStorage.getItem("menusId")),
        "addList": store.getState().shoppingCartsReducer.dataList
    }
    console.log(data)
    await OrdersApi.addOrdersInfo(data)
}
export const getOrderList = async () => {
    let data = {
        "offset": store.getState().ordersReducer.offset,
        "pageSize": store.getState().ordersReducer.pageSize,
        "menusName": store.getState().optionsReducer.menusOptionSelected,
        "status": store.getState().optionsReducer.statusOptionSelected,
    }
    let res = await OrdersApi.getOrderList(data)
    store.dispatch({
        type: store.getState().ordersReducer.action.setDataList,
        payload: res.data
    })
}
export const getOrderInfo = async (ordersParentsId) => {
    let data = {
        "ordersParentsId": ordersParentsId,
    }
    let res = await OrdersApi.getOrderInfo(data)
    store.dispatch({
        type: store.getState().ordersReducer.action.setDataInfo,
        payload: res.data
    })
}
export const deleteOrderInfo = async (ordersParentsId) => {
    let data = {
        "ordersParentsId": ordersParentsId,
    }
    let res = await OrdersApi.deleteOrderInfo(data)
    store.dispatch({
        type: store.getState().ordersReducer.action.setDataInfo,
        payload: res.data
    })
}
