const initState = {
    action: {
        setLoad: "loading/setLoad",
        setButtonLoad: "loading/setButtonLoad",
        setModalLoad: "loading/setModalLoad",
    },
    isLoading: false,
    isButtonLoading: false,
    isModalLoading: false,
};
const loadingReducer = (state = initState, action) => {
    switch (action.type) {
        case state.action.setLoad:
            return {
                ...state,
                isLoading: action.payload
            }
        case state.action.setButtonLoad:
            return {
                ...state,
                isButtonLoading: action.payload
            }
        case state.action.setModalLoad:
            return {
                ...state,
                isModalLoading: action.payload
            }
        default:
            return state;
    }
};

export default loadingReducer;
