import {combineReducers, createStore, applyMiddleware} from 'redux';
import thunk from "redux-thunk";
import loadingReducer from '../reducer/loading';
import modalReducer from "../reducer/modal";
import processingReducer from "../reducer/processing";
import itemsReducer from "../reducer/items";
import shoppingCartsReducer from "../reducer/shoppingCarts";
import ordersReducer from "../reducer/orders";
import usersReducer from "../reducer/users";
import optionsReducer from "../reducer/options";
const reducers = combineReducers({
    modalReducer,
    loadingReducer,
    processingReducer,
    itemsReducer,
    shoppingCartsReducer,
    ordersReducer,
    usersReducer,
    optionsReducer,
})
const store = createStore(reducers, applyMiddleware(thunk));

export default store;
