import React from "react";
import {Button, Card, Col, message, Modal, Row} from "antd";
import store from "../../../store";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {deleteOrderInfo, getOrderList} from "../../..//model/orders";
const {confirm} = Modal;
const goToPage = (item) => {
    let action = store.getState().processingReducer.routerAction.ordersInfo
    window.location.href = "/processing?action=" + action + "&id=" + item.id
}
const deleteOrder = async (id) => {
    try {
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: true});
        await deleteOrderInfo(id)
        store.dispatch({type: store.getState().ordersReducer.action.setOffset, payload: 1});
        await getOrderList()
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
        message.success("申請成功")
    } catch (e){
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
    }
}
const showConfirm = (item) => {
    confirm({
        title: "是否申請退訂？",
        content: "訂單編號 #" + item.id,
        icon: <ExclamationCircleOutlined/>,
        okText: "確認",
        cancelText: "取消",
        onOk() {
            deleteOrder(item.id)
        },
        onCancel() {
            console.log('Cancel');
        },
    });
}
const titleStyle = {width: "90px", textAlign: "right", lineHeight: "32px"}
const itemStyle = {lineHeight: "32px"}
const Order = ({ item, index }) => {
    return <Card key={index} title={"訂單編號 #" + item.id} style={{marginBottom: "12px"}}>
        <Row style={{marginBottom: "8px"}}>
            <Col style={titleStyle}>團購單：</Col>
            <Col flex={"auto"} style={itemStyle}>{item.menusName}</Col>
        </Row>
        <Row style={{marginBottom: "8px"}}>
            <Col style={titleStyle}>訂購人：</Col>
            <Col flex={"auto"} style={itemStyle}>{item.usersName}</Col>
        </Row>
        <Row style={{marginBottom: "8px"}}>
            <Col style={titleStyle}>訂單狀態：</Col>
            <Col flex={"auto"} style={itemStyle}>{item.statusName}</Col>
        </Row>
        <Row style={{marginBottom: "8px"}}>
            <Col style={titleStyle}>訂購日期：</Col>
            <Col flex={"auto"} style={itemStyle}>{item.createdAt}</Col>
        </Row>
        <Row style={{marginBottom: "8px"}}>
            <Col style={titleStyle}>審核日期：</Col>
            <Col flex={"auto"} style={itemStyle}>{item.updatedAt}</Col>
        </Row>
        <Row style={{marginBottom: "8px"}}>
            <Col style={titleStyle}>操作：</Col>
            <Col flex={"auto"}>
                <Button style={{marginRight: "6px"}} type="primary" onClick={() => goToPage(item)}>查看明細</Button>
                <Button
                    disabled={(item.statusName === "已取消" || item.statusName === "已完成")}
                    onClick={() => showConfirm(item)}>
                    申請退訂
                </Button>
            </Col>
        </Row>
    </Card>
}
export default Order
