import store from "../store";
import MenusApi from "../apis/menus";

export const getMenuItemsList = async () => {
    let data = {
        offset: store.getState().itemsReducer.offset,
        pageSize: store.getState().itemsReducer.pageSize,
        menus_id: localStorage.getItem("menusId"),
        groups_id: localStorage.getItem("groupsId"),
    }
    let res = await MenusApi.getMenuItemsList(data)
    store.dispatch({type: store.getState().itemsReducer.action.setDataList, payload: res.data})
};
export const getItemsInfo = async () => {
    try {
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: true});
        let data = {
            id: 0,
        }
        let res = await MenusApi.getItemsInfo(data)
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
    } catch {
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
    }
};
export const getMenusOptions = async () => {
    let res = await MenusApi.getMenusOptions()
    store.dispatch({
        type: store.getState().optionsReducer.action.setMenusOptions,
        payload: res.data.dataList
    });
};
