import axios from "../utils/axios";

class ChannelsApi {
    static async addChannelsInfo(data){
        return axios.fetch({
            method: "post",
            url: "/api/v1/auth/channels/add-channels-info",
            data: data,
        });
    }
}

export default ChannelsApi
