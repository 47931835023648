import axios from "../utils/axios";

class OrdersApi {
    static async getOrderStatusOptions(){
        return axios.fetch({
            method: "get",
            url: "/api/v1/auth/orders/order-status-options"
        });
    }
    static async addOrdersInfo(data){
        return axios.fetch({
            method: "post",
            url: "/api/v1/auth/orders/add-orders-info",
            data: data
        });
    }
    static async getOrderList(data){
        let params = new URLSearchParams(data)
        return axios.fetch({
            method: "get",
            url: "/api/v1/auth/orders/orders-list?"+params,
            data: data
        });
    }
    static async getOrderInfo(data){
        let params = new URLSearchParams(data)
        return axios.fetch({
            method: "get",
            url: "/api/v1/auth/orders/orders-info?"+params,
            data: data
        });
    }
    static async deleteOrderInfo(data){
        return axios.fetch({
            method: "delete",
            url: "/api/v1/auth/orders/delete-orders-info",
            data: data
        });
    }
}

export default OrdersApi
