import React, {useState} from "react";
import {Typography, Row, Col} from 'antd'

const {Title, Paragraph} = Typography;

const steps = [
    {
        title: '步驟1.',
        description: "新增",
        content: '先到後台新增一張團購單。',
        imgSrc: require('../../assets/teach/store/1.png')

    },
    {
        title: '步驟2.',
        description: "上傳菜單",
        content: '上傳一張單，填寫團購單名稱接著選擇你想開單的群組。',
        imgSrc: require('../../assets/teach/store/3.png')
    },
    {
        title: '步驟3.',
        description: "完成",
        content: '接著Nicole就會發出團購單囉！',
        imgSrc: require('../../assets/teach/store/line.png')
    },
];
const style = {
    xs: {span: 24, offset: 0},
    sm: {span: 12, offset: 6},
    md: {span: 12, offset: 6},
    lg: {span: 12, offset: 6},
}
const Teach = () => {
    const [current, setCurrent] = useState(0)
    const onChange = (value) => {
        setCurrent(value);
    };
    return steps.map((item, index) => {
        return <Row style={{padding: "24px"}} key={index}>
            <Col span={24} style={{textAlign: "left", marginBottom: "24px"}}>
                <Title level={4} style={{color: "#274b10"}}>
                    {item.title} {item.description}
                </Title>
                <Paragraph>
                    <blockquote>{item.content}</blockquote>
                </Paragraph>

            </Col>
            <Col span={24} style={{textAlign: "center", marginBottom: "24px"}}>
                {index === 2 ?
                    <img src={item.imgSrc} alt="" style={{maxHeight: "680px"}}/>
                    :
                    <img src={item.imgSrc} alt="" style={{maxWidth: "100%", maxHeight: "auto"}}/>
                }
            </Col>
        </Row>
    })
}
export default Teach
