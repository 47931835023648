import React from "react";
import {Select} from "antd";
const OrderStatusOption = ({ options, onChange }) => {
    return <Select
        style={{width: "90%"}}
        defaultValue={"全部"}
        onChange={onChange}
        options={options}
    />
}
export default OrderStatusOption
