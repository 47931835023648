import axios from "../utils/axios";

class ShoppingCartsApi {
    static async getShoppingCartsList(data){
        let params = new URLSearchParams(data)
        return axios.fetch({
            method: "get",
            url: "/api/v1/auth/shopping-carts/shopping-carts-list?"+params
        });
    }
    static async addShoppingCartsInfo(data){
        return axios.fetch({
            method: "post",
            url: "/api/v1/auth/shopping-carts/add-shopping-carts-info",
            data: data
        });
    }
    static async deleteShoppingCartsInfo(data){
        return axios.fetch({
            method: "delete",
            url: "/api/v1/auth/shopping-carts/delete-shopping-carts-info",
            data: data
        });
    }
}

export default ShoppingCartsApi
