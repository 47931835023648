import axios from 'axios';
import auth from './auth';
import {message} from 'antd'

export default {
    fetch(config = {}) {
        const instance = axios.create({
            baseURL: process.env.REACT_APP_URL,
            ...config,
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Access-Control-Allow-Origin": "*",
                "authorization": auth.getToken(),
                ...config.headers
            }
        });
        return this.send(instance, config)
    },
    uploadFile(config = {}) {
        const instance = axios.create({
            ...config,
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Access-Control-Allow-Origin": "*",
                ...config.headers
            }
        });
        return this.send(instance, config)
    },
    send(instance, config) {
        instance.interceptors.request.use(function (config) {
            return config;
        }, async function (error) {
            message.error(error.message)
            return Promise.reject(error);
        });
        instance.interceptors.response.use(function (response) {
            return response.data;
        }, async function (error) {
            if ([401, 409].indexOf(error.response.status) >= 0) {
                auth.clearAuth()
            }
            message.error(error.response?.data.msg || error.message)
            return Promise.reject(error);
        });
        return instance.request(config)
    },
}
