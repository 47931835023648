import store from "../store";
import { message } from "antd";
import UsersApi from "../apis/users";
import Auth from "../utils/auth";

export const addUsersInfo = async () => {
    try {
        let data = {
            "lineToken": store.getState().usersReducer.userInfo.userId,
            "displayName": store.getState().usersReducer.userInfo.displayName,
            "pictureUrl": store.getState().usersReducer.userInfo.pictureUrl
        }
        await UsersApi.addUsersInfo(data)
    } catch {}
};
export const getAuthToken = async () => {
    try {
        let data = {
            lineToken: store.getState().usersReducer.userInfo.userId,
            groupsId: parseInt(localStorage.getItem("groupsId")),
        }
        let res = await UsersApi.getAuthToken(data)
        Auth.setToken(res.data.token)
    } catch {}
};
