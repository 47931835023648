const initState = {
    action: {
        setModal: "modal/setModal",
        setItemModal: "modal/setItemModal",
        setErrorModal: "modal/setErrorModal",
        setErrorModalMessage: "modal/setErrorModalMessage",
    },
    isErrorModalOpen: false,
    errorMessage: "",
    isModalOpen: false,
    isItemModalOpen: false,
};
const modalReducer = (state = initState, action) => {
    switch (action.type) {
        case state.action.setModal:
            return {
                ...state,
                isModalOpen: action.payload,
            };
        case state.action.setItemModal:
            return {
                ...state,
                isItemModalOpen: action.payload,
            };
        case state.action.setErrorModal:
            return {
                ...state,
                isErrorModalOpen: action.payload,
            };
        case state.action.setErrorModalMessage:
            return {
                ...state,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
};

export default modalReducer;
