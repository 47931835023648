import DrawBar from "../components/DrawBar";
import LazyLoadImage from "../../views/components/LazyLoadImage";
import {Col, Image, Row, Typography, Spin} from "antd";
import Card from "antd/es/card/Card";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getOrderInfo, getOrderList} from "../../model/orders";
import params from "../../utils/params";
import store from "../../store";

const {Title, Paragraph, Text} = Typography
const OrdersInfo = () => {
    const ordersParentsId = params.findGetParameter("id")
    const dataInfo = useSelector((state) => state.ordersReducer.dataInfo)
    const isLoading = useSelector((state) => state.loadingReducer.isLoading)
    const setupData = async () => {
        try {
            store.dispatch({ type: store.getState().loadingReducer.action.setLoad, payload: true });
            await getOrderInfo(ordersParentsId)
            store.dispatch({ type: store.getState().loadingReducer.action.setLoad, payload: false });
        }catch (e) {
            setTimeout(()=>{
                window.location.href = "/orders"
            },500)

        }
    };
    useEffect(() => {
        setupData()
    }, [])
    return <>
        <DrawBar/>
        <div className={"container"}>
            <Spin spinning={isLoading}>
                <Row gutter={[12, 24]}>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Title level={3} style={{marginTop: "16px"}}>訂單詳情</Title>
                    </Col>
                    <Col span={24}>
                        <Card title={"訂單編號 #" + dataInfo.id}>
                            <Row>
                                <Col span={12}>
                                    <Row>
                                        <Col flex={"70px"} style={{textAlign: "right"}}>團購單：</Col>
                                        <Col flex={"auto"}>{dataInfo.menusName}</Col>
                                    </Row>
                                    <Row>
                                        <Col flex={"70px"} style={{textAlign: "right"}}>訂購人：</Col>
                                        <Col flex={"auto"}>{dataInfo.usersName}</Col>
                                    </Row>
                                    <Row>
                                        <Col flex={"70px"} style={{textAlign: "right"}}>總數量：</Col>
                                        <Col flex={"auto"}>{dataInfo.allAmount}</Col>
                                    </Row>
                                    <Row>
                                        <Col flex={"70px"} style={{textAlign: "right"}}>總價格：</Col>
                                        <Col flex={"auto"}>NT${dataInfo.allPrice}</Col>
                                    </Row>
                                    <Row>
                                        <Col flex={"70px"}>訂單狀態：</Col>
                                        <Col flex={"auto"}>{dataInfo.statusName}</Col>
                                    </Row>
                                    <Row>
                                        <Col flex={"70px"}>訂購日期：</Col>
                                        <Col flex={"auto"}>{dataInfo.createdAt}</Col>
                                    </Row>
                                    <Row>
                                        <Col flex={"70px"}>審核日期：</Col>
                                        <Col flex={"auto"}>{dataInfo.updatedAt}</Col>
                                    </Row>
                                </Col>
                                <Col style={{textAlign: "center"}} span={12}>
                                    <div style={{width: "150px", margin: "0px auto"}}>
                                        <LazyLoadImage src={dataInfo.filePath} preview={false}/>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card title={"品項"} style={{marginBottom: "22px"}}>
                            {dataInfo.itemsList.map((item, index) => {
                                return <Row key={index}
                                            style={{padding: "6px 6px 0px 6px", marginBottom: "8px", border: "solid 1px #6a6a6a6a"}}>
                                    <Col flex="126px" style={{paddingRight: "12px"}}>
                                        <LazyLoadImage src={item.filePath} preview={false}/>
                                    </Col>
                                    <Col flex="auto">
                                        <Row>
                                            <Col flex="auto" style={{lineHeight: "26px"}}>
                                                <Paragraph>
                                                    <Text>{item.itemsName}</Text>
                                                    <br></br>
                                                    <Text disabled>規格：{item.specName}</Text>
                                                    <br></br>
                                                    <Text type="danger">NT${item.price}</Text>
                                                    <br></br>

                                                    <Text>數量：{item.amount}</Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            })}
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    </>
}

export default OrdersInfo
