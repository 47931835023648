import React from "react";
import {Row, Col} from 'antd'

const Qrcode = () => {
    return (
        <Row>
            <Col span={24} style={{textAlign:"center"}}>
                <img src={require("../../assets/qrcode/L_groupbuy_qr.png")} alt="加入好友" border="0"/>
            </Col>
            <Col span={24} style={{textAlign:"center", paddingBottom: "48px"}}>
                <a href="https://lin.ee/SIPEEzS" target="_blank">
                    <img
                        src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                        alt="加入好友"
                        height="40px"
                        border="0"
                    />
                </a>
            </Col>
        </Row>
    );
}
export default Qrcode
