import React from "react";
import Order from "./Order";
import {Empty} from "antd";
const OrderList = ({dataList}) => {
    const dataContent = dataList.map((item, index) => {
        return (
            <Order
                key={index}
                item={item}
                index={index}
            />
        );
    })
    const emptyContent = <Empty description={<span>暫無資料</span>}></Empty>
    return (
        <>
            {
                (dataList.length > 0) ? dataContent : emptyContent
            }
        </>
    )
}
export default OrderList
