import {Card, Col, Image} from "antd";
import LazyLoadImage from "../../components/LazyLoadImage";
import {ShoppingCartOutlined} from "@ant-design/icons";
import React from "react";
import store from "../../../store";
const layoutFormat = {
    xs: 24, sm: 12, md: 10, lg: 10, xl: 10,
}
const {Meta} = Card;
const Item = ({ item, index }) => {
    const onClick = (item) => {
        store.dispatch({type: store.getState().itemsReducer.action.setDataInfo, payload: item})
        store.dispatch({type: store.getState().modalReducer.action.setItemModal, payload: true})
    }
    return <Col {...layoutFormat} key={index} style={{margin: "0px auto 24px auto"}}>
            <Card
                cover={<LazyLoadImage src={item.filePath}/>}
                actions={[
                    <ShoppingCartOutlined style={{ fontSize: '26px',}} key={item} onClick={(e) => onClick(item)}/>,
                ]}
            >
                <Meta
                    title={item.name}
                    description={"NT$" + item.price}
                />
            </Card>
        </Col>
}
export default Item
