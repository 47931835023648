import {Col, Row, Typography, Card, Image, Spin, Skeleton, Pagination} from "antd";
import {useSelector} from "react-redux";
import AddShoppingCartModal from "./components/AddShoppingCartModal";
import DrawBar from "../components/DrawBar";
import React, {useCallback, useEffect, useState} from "react";
import {getMenuItemsList} from "../../model/menus";
import ItemList from "./components/ItemList";
import store from "../../store";

const {Title} = Typography

const Items = () => {
    const dataList = useSelector((state) => state.itemsReducer.dataList)
    const isLoading = useSelector((state) => state.loadingReducer.isLoading)
    const setupData = useCallback(async () => {
        try {
            store.dispatch({ type: store.getState().loadingReducer.action.setLoad, payload: true });
            await getMenuItemsList()
            store.dispatch({ type: store.getState().loadingReducer.action.setLoad, payload: false });
        }catch (e) {
            store.dispatch({ type: store.getState().loadingReducer.action.setLoad, payload: false });
        }
    }, []);
    const onChange = async (value) => {
        store.dispatch({
            type: store.getState().itemsReducer.action.setOffset,
            payload: value,
        });
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        await setupData();
    }
    useEffect(() => {
        setupData()
    }, [])
    return <>
        <DrawBar/>
        <div className={"container"}>
            <Spin spinning={isLoading}>
                <AddShoppingCartModal/>
                <Row gutter={[12, 24]}>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Title level={3} style={{marginTop: "16px"}}>團購品項</Title>
                    </Col>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Pagination
                            current={store.getState().itemsReducer.offset}
                            onChange={async (value) => {
                                store.dispatch({
                                    type: store.getState().itemsReducer.action.setOffset,
                                    payload: value,
                                });
                                await setupData();
                            }}
                            total={store.getState().itemsReducer.totalCount}
                            pageSize={store.getState().itemsReducer.pageSize}
                        />
                    </Col>
                    <Col span={24} >
                        <ItemList dataList={dataList}/>
                    </Col>
                    <Col span={24} style={{textAlign: "center", margin: "24px auto"}}>
                        <Pagination
                            current={store.getState().itemsReducer.offset}
                            onChange={onChange}
                            total={store.getState().itemsReducer.totalCount}
                            pageSize={store.getState().itemsReducer.pageSize}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>
    </>
}
export default Items
