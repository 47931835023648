import {getShoppingCartsList, submitShoppingCart} from "../../../model/shoppingCarts";
import {Button, message} from "antd";
import store from "../../../store";

const submit = async () => {
    try {
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: true});
        await submitShoppingCart()
        await getShoppingCartsList()
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
    } catch (e) {
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
    }
}
const SubmitShoppingCartButton = () => {
    return <Button style={{width: "100%", marginBottom: "16px"}} onClick={submit}>送出訂單</Button>
}
export default SubmitShoppingCartButton
