import store from "../store";
import {message} from "antd";
import ShoppingcartsApi from "../apis/shoppingcarts";
import {addOrdersInfo} from "./orders";

export const submitShoppingCart = async () => {
    try{
        await addOrdersInfo()
        message.success("送出成功");
    }catch (e) {}
}
export const addShoppingCartsInfo = async () => {
    let data = {
        "menusId": parseInt(localStorage.getItem("menusId")),
        "itemSpecsId": store.getState().itemsReducer.selectedSpecsId,
        "amount": store.getState().itemsReducer.amount,
    }
    await ShoppingcartsApi.addShoppingCartsInfo(data)
}
export const getShoppingCartsList = async () => {
    let data = {
        "offset": 1,
        "pageSize": 1000,
    }
    let res = await ShoppingcartsApi.getShoppingCartsList(data)
    store.dispatch({type: store.getState().shoppingCartsReducer.action.setDataList, payload: res.data})
}
export const deleteShoppingCartsInfo = async (index) => {
    try {
        let selectedItems = store.getState().shoppingCartsReducer.dataList.filter((item, i) => {
            if (i === index) {
                return item;
            }
        })
        if (!selectedItems[0]) {
            return message.error("資料錯誤")
        }
        let data = {
            "menuItemsId": parseInt(selectedItems[0].menuItemsId),
            "itemSpecsId": parseInt(selectedItems[0].itemSpecsId),
        }
        await ShoppingcartsApi.deleteShoppingCartsInfo(data)
    } catch (e) {
    }
}


