import React, {useState, useEffect} from "react";
import noImage from "../../assets/no-image.png";
import {Spin, Image} from "antd";
import {LoadingOutlined} from '@ant-design/icons';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

function LazyLoadImage({src, preview = false}) {
    const [isLoading, setLoading] = useState(true)
    const [path, setPath] = useState(src)
    useEffect(() => {
        setPath(src)
    }, [src]);

    const onError = (e) => {
        e.target.src = noImage
    }
    const onLoad = () => {
        setLoading(false)
    };
    return <div style={{textAlign: "center"}}>
        <Spin spinning={isLoading} indicator={antIcon}>
            <img style={{width: "100%"}} data-image-watcher={"false"} data-src={path} onLoad={onLoad} onError={onError}/>
        </Spin>
    </div>
}

export default LazyLoadImage
