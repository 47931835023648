import {Button, Col, Divider, Drawer, Row} from "antd";
import {useEffect, useState} from "react";
import {MenuOutlined, ShoppingOutlined, UnorderedListOutlined, ShopOutlined} from "@ant-design/icons";
import store from "../../store";
import {useLocation} from "react-router-dom";

const DrawBar = () => {
    let currentLocation = useLocation();
    const [drawOpen, setDrawOpen] = useState(false);
    const items = [
        {name: "團購品項", icon: <ShopOutlined />, action: store.getState().processingReducer.routerAction.items},
        {name: "我的購物車", icon: <ShoppingOutlined />, action: store.getState().processingReducer.routerAction.shoppingCarts},
        {name:"我的訂單", icon: <UnorderedListOutlined />, action: store.getState().processingReducer.routerAction.orders},
    ]
    const goToPage = (index) => {
        let action = items[index].action
        let url = "/processing?action="+action
        let filter = [
            store.getState().processingReducer.routerAction.items,
            store.getState().processingReducer.routerAction.shoppingCarts
        ]
        if (filter.indexOf(action) >= 0){
            url += "&menusId="+localStorage.getItem("menusId")
            url += "&groupsId="+localStorage.getItem("groupsId")
        }
        if (action === currentLocation.pathname.replaceAll("/", "")){
            return
        }
        location.replace(url)
    }
    useEffect(()=>{},[])
    return <>
        <Drawer
            title="功能選單"
            width={300}
            placement={"left"}
            onClose={() => setDrawOpen(false)}
            open={drawOpen}
            key={"left"}
        >
            {items.map((item, index) => {
                return <div key={index}>
                    <Button style={{border: "none", margin: 0, padding: 0}} onClick={()=> goToPage(index)}>
                        {item.icon} {item.name}
                    </Button>
                    <Divider style={{marginTop: 0}}/>
                </div>
            })}
        </Drawer>
        <MenuOutlined onClick={() => setDrawOpen(true)} style={{padding: "22px 22px 22px 30px"}}/>
    </>
}

export default DrawBar
