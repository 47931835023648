import axios from "../utils/axios";

class MenusApi {
    static async getMenusOptions() {
        return axios.fetch({
            method: "get",
            url: "/api/v1/auth/menus/options"
        });
    }
    static async getMenuItemsList(data){
        let params = new URLSearchParams(data)
        return axios.fetch({
            method: "get",
            url: "/api/v1/auth/menu-items/menu-items-list?"+params
        });
    }
    static async getItemsInfo(data){
        let params = new URLSearchParams(data)
        return axios.fetch({
            method: "get",
            url: "/api/v1/auth/items/items-info?id="+params,
        });
    }
}

export default MenusApi
