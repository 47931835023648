import React, {useState} from "react";
import {Layout, Menu, Row, Col} from 'antd';
import {MenuOutlined} from "@ant-design/icons";

const {Header} = Layout;
const logo = require('../../assets/logo-g.png')
const navItem = [
    {label: "操作說明", id: "intro"},
    {label: "加入我們", id: "follow-us"},
]
const Navbar = () => {
    const [key, setKey] = useState()
    const goToIndex = () => {
        setKey([0])
        scrollView("index")
    }
    const onClick = (e) => {
        setKey([e.key])
        scrollView(navItem[e.key - 1].id)
    }
    const scrollView = (id) => {
        document.querySelector('[id="' + id + '"]').scrollIntoView({
            behavior: 'smooth'
        })
    }
    return <Header style={{background: "#d5e6cd", padding: "0 26px"}} id="index">
        <Row>
            <Col flex="60px">
                <img src={logo} alt="nicole_logo" style={{width: "60px"}} onClick={goToIndex}/>
            </Col>
            <Col flex="auto">
                <Menu
                    inlineIndent={1}
                    className="index-menu"
                    onClick={onClick}
                    overflowedIndicator={<MenuOutlined style={{color: "#274b10"}}/>}
                    theme="light"
                    mode="horizontal"
                    defaultSelectedKeys={['0']}
                    selectedKeys={key}
                    items={navItem.map((item, index) => {
                        const key = index + 1;
                        return {
                            key,
                            label: item.label,
                        };
                    })}
                />
            </Col>
        </Row>
    </Header>
}
export default Navbar
