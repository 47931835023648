import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "antd/dist/antd.min.css"
import "./css/index.css"
import Index from "./views";
import Liff from "./views/processing";
import Orders from "./views/orders";
import OrdersInfo from "./views/orders/info";
import {LiffProvider} from "react-liff";
import ErrorModal from "./views/components/ErrorModal";
import Items from "./views/items";
import ShoppingCarts from "./views/shoppingCarts";
import ListenImage from "./views/components/ListenImage";
const root = ReactDOM.createRoot(document.getElementById('root'));
const liffId = process.env.REACT_APP_LINE_LIFF_ID;
const stubEnabled = process.env.NODE_ENV !== 'production';
root.render(
    <Router>
        <Provider store={store}>
            <ErrorModal />
            <ListenImage />
            <Switch>
                {/*首頁*/}
                <Route exact path="/">
                    <Index/>
                </Route>
                {/*團購品項*/}
                <Route exact path="/items">
                    <Items/>
                </Route>
                {/*我的訂單*/}
                <Route exact path="/orders">
                    <Orders/>
                </Route>
                {/*我的訂單明細*/}
                <Route exact path="/orders/info">
                    <OrdersInfo/>
                </Route>
                {/*我的購物車*/}
                <Route exact path="/shopping-carts">
                    <ShoppingCarts/>
                </Route>
                <LiffProvider liffId={liffId} stubEnabled={stubEnabled}>
                    {/*導頁*/}
                    <Route exact path="/processing">
                        <Liff/>
                    </Route>
                </LiffProvider>
            </Switch>
        </Provider>
    </Router>
);
