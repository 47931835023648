const initState = {
    routerAction: {
        items: "items",
        shoppingCarts: "shopping-carts",
        orders: "orders",
        ordersInfo: "orders-info",
    },
    action: {
        setModal: "modal/setModal",
    },
    isModalOpen: false,
};
const processingReducer = (state = initState, action) => {
    switch (action.type) {
        case state.action.setModal:
            return {
                ...state,
                isModalOpen: action.payload,
            };
        default:
            return state;
    }
};

export default processingReducer;
