const initState = {
    action: {
        setOffset: "items/setOffset",
        setPageSize: "items/setPageSize",
        setDataList: "items/setDataList",
        setDataInfo: "items/setDataInfo",
        setAmount: "items/setAmount",
        setSelectedSpecsId: "items/setSelectedSpecsId",
    },
    offset: 1,
    pageSize: 12,
    dataList: [],
    totalCount: 0,
    dataInfo: {},
    selectedSpecsId: 0,
    amount: 0,
};
const itemsReducer = (state = initState, action) => {
    switch (action.type) {
        case state.action.setOffset:
            return {
                ...state,
                offset: action.payload
            }
        case state.action.setPageSize:
            return {
                ...state,
                pageSize: action.payload
            }
        case state.action.setDataList:
            return {
                ...state,
                dataList: action.payload.dataList,
                totalCount: action.payload.totalCount,
            }
        case state.action.setDataInfo:
            return {
                ...state,
                dataInfo: action.payload,
            }
        case state.action.setAmount:
            return {
                ...state,
                amount: action.payload,
            }
        case state.action.setSelectedSpecsId:
            return {
                ...state,
                selectedSpecsId: action.payload,
            }
        default:
            return state;
    }
};

export default itemsReducer;
