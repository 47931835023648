import DrawBar from "../components/DrawBar";
import {Button, Col, Row, Typography, message, Modal, Select, Form, Pagination, Spin} from "antd";
import React, {useCallback, useEffect} from "react";
import {useSelector} from "react-redux";
import store from "../../store";
import {getOrderStatusOptions, getOrderList} from "../../model/orders";
import {getMenuItemsList, getMenusOptions} from "../../model/menus";
import OrderList from "./components/OrderList";
import OrderStatusOption from "../components/options/OrderStatusOption";
import MenusNameOption from "../components/options/MenusNameOption";

const {Title} = Typography

const Orders = () => {
    const dataList = useSelector((state) => state.ordersReducer.dataList)
    const menusOptions = useSelector((state) => state.optionsReducer.menusOptions)
    const statusOptions = useSelector((state) => state.optionsReducer.statusOptions)
    const offset = useSelector((state) =>state.ordersReducer.offset)
    const totalCount = useSelector((state) =>state.ordersReducer.totalCount)
    const pageSize = useSelector((state) =>state.ordersReducer.pageSize)
    const isLoading = useSelector((state) => state.loadingReducer.isLoading)
    const setupData = async () => {
        try {
            store.dispatch({ type: store.getState().loadingReducer.action.setLoad, payload: true });
            await getOrderList()
            store.dispatch({ type: store.getState().loadingReducer.action.setLoad, payload: false });
        }catch (e) {
            store.dispatch({ type: store.getState().loadingReducer.action.setLoad, payload: false });
        }
    };
    const onChangePage = async (value) => {
        store.dispatch({
            type: store.getState().ordersReducer.action.setOffset,
            payload: value,
        });
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setupData()
    }
    const onChangeMenusNameOption = async(val) => {
        store.dispatch({
            type: store.getState().optionsReducer.action.setMenusOptionSelected,
            payload: val
        })
        setupData()
    }
    const onChangeStatusOptions = async(val) => {
        store.dispatch({
            type: store.getState().optionsReducer.action.setStatusOptionSelected,
            payload: val
        })
        setupData()
    }
    useEffect(() => {
        getOrderStatusOptions()
        getMenusOptions()
        setupData()
    }, [])
    return <>
        <DrawBar/>
        <div className={"container"}>
            <Spin spinning={isLoading} >
                <Row gutter={[12, 24]}>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Title level={3} style={{marginTop: "16px"}}>我的訂單</Title>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col flex={"50px"} style={{lineHeight: "32px"}}>狀態：</Col>
                            <Col flex={"auto"}>
                                <OrderStatusOption onChange={onChangeStatusOptions} options={statusOptions}/>
                            </Col>
                            <Col flex={"60px"} style={{lineHeight: "32px"}}>團購單：</Col>
                            <Col flex={"auto"}>
                                <MenusNameOption onChange={onChangeMenusNameOption} options={menusOptions}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} style={{textAlign: "center", margin: "12px auto"}}>
                        <Pagination
                            current={offset}
                            onChange={onChangePage}
                            total={totalCount}
                            pageSize={pageSize}
                        />
                    </Col>
                    <Col span={24}>
                        <OrderList dataList={dataList}/>
                    </Col>
                    <Col span={24} style={{textAlign: "center", margin: "12px auto"}}>
                        <Pagination
                            current={offset}
                            onChange={onChangePage}
                            total={totalCount}
                            pageSize={pageSize}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>
    </>
}

export default Orders
