const initState = {
    action: {
        setUserInfo: "modal/setUserInfo",
    },
    userInfo: {
        userId: "",
        displayName: "",
        pictureUrl: "",
    },
};
const usersReducer = (state = initState, action) => {
    switch (action.type) {
        case state.action.setUserInfo:
            return {
                ...state,
                userInfo: action.payload,
            };
        default:
            return state;
    }
};

export default usersReducer;
