const tokenKey = "group_buy_token"
let token = localStorage.getItem(tokenKey);
export default {
    isLogin() {
        return token !== null && token !== '';
    },
    setToken(t) {
        localStorage.setItem(tokenKey, "Bearer " + t + '');
        token = "Bearer " + t;
    },
    getToken() {
        return token
    },
    clearAuth() {
        token = null;
        localStorage.removeItem(tokenKey);
    },
}
