import {Col, InputNumber, message, Row, Typography} from "antd";
import React from "react";
import {DeleteOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import store from "../../../store";
import LazyLoadImage from "../../components/LazyLoadImage";
import {deleteShoppingCartsInfo, getShoppingCartsList} from "../../../model/shoppingCarts";
const {Text, Paragraph} = Typography
const deleteItem = async (index) => {
    try {
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: true});
        await deleteShoppingCartsInfo(index)
        await getShoppingCartsList()
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
        message.success("刪除成功")
    } catch (e){
        store.dispatch({type: store.getState().loadingReducer.action.setLoad, payload: false});
    }
}
const ShoppingCart = ({ item, index }) => {
    return (
        <Row key={index}
             style={{padding: "6px 6px 0px 6px", marginBottom: "8px", border: "solid 1px #6a6a6a6a"}}>
            <Col flex="126px" style={{paddingRight: "12px"}}>
                <LazyLoadImage src={item.filePath} />
            </Col>
            <Col flex="auto">
                <Row>
                    <Col flex="auto" style={{lineHeight: "26px"}}>
                        <Paragraph>
                            <Text>{item.itemsName}</Text>
                            <br></br>
                            <Text disabled>規格：{item.itemSpecsName}</Text>
                            <br></br>
                            <Text type="danger">NT${item.itemsPrice}</Text>
                            <br></br>
                            <Text style={{lineHeight: "32px"}}>數量：</Text>
                            <InputNumber
                                style={{width: "140px"}}
                                value={item.amount}
                                addonBefore={<MinusOutlined onClick={() => store.dispatch({
                                    type: store.getState().shoppingCartsReducer.action.setMinusAmount,
                                    payload: index
                                })}/>}
                                addonAfter={<PlusOutlined onClick={() => store.dispatch({
                                    type: store.getState().shoppingCartsReducer.action.setAddAmount,
                                    payload: index
                                })}/>}
                            />
                        </Paragraph>
                    </Col>
                    <Col flex="20px" style={{textAlign: "right"}}>
                        <DeleteOutlined
                            style={{fontSize: "22px", color: "red"}}
                            onClick={() => deleteItem(index)}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ShoppingCart
